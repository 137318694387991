$link-color: #286dc0;
$link-hover-color: $link-color !default;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500&display=swap');

/* CSS Document */

@font-face {
	font-family: "Chaparral Pro";
	src: url("../assets/fonts/ChaparralPro-Regular.otf");
}

body {
    font-family: "Chaparral Pro", "Fira Sans", Cambria, "Hoefler Text", "Liberation Serif", Times, "Times New Roman", serif;
    background-color: aliceblue;
    line-height: 24px;
    font-kerning: normal;
    font-weight: 300;
    font-size: 17px;
}

.text-center p {
    text-align: center !important;
}

.question {
    font-weight: bold;
}

span.authors {
    font-style: italic;
    font-size: 15px;
}

span.venue {
    font-size: 15px;
    //font-variant: small-caps;
    font-feature-settings: "smcp" 1;
}

.clear-both {
    clear: both;
}

a {
    color: #C0F;
}

p {
    text-align: justify;
}

a:hover {
    color: #f14e32;
}

#header {
    width: 80%;
    margin: 10px auto auto auto;
}

#header>div {
    display: inline-block;
}

#headshot {
    float: right;
    margin-bottom: 15px;
    border-style: solid;
    border-radius: 10px;
}

#heading {
    float: left;
}

h1 {
    /* font-size: xx-large; */
    font-size: 50px;
    line-height: 50px;
    /*color: #333 */
    color: rgb(51, 51, 51);
    font-weight: 500;
}

h2 {
    font-size: x-large;
    color: #666
}

h5 {
    font-weight: 300;
    color: #666
}

#information {
    clear: both;
}

.content {
    max-width: 800px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

#socialmedia {
    display: inline-block;
    float: right;
}

#socialmedia * img {
    display: inline;
}

#contact {
    display: inline-block;
    float: left;
}

li:not(:last-child) {
    margin-bottom: 5px;
}

.btn-bib {
     font-weight: 200;
     border-color: #3081d1;
     font-size: 12px;
}